import React, { useEffect, useRef } from "react";
import { Wrapper } from "./InfoCircle.styled"
import 'twin.macro'
import circle from "../../../images/About/about_circle_2x.png"
import gsap from 'gsap';


const InfoCircle = ({data})=> {  

    const ring = useRef();

    useEffect(() => {
        const $ = gsap.utils.selector(ring);

        let tl = new gsap.timeline({
            scrollTrigger: {
              trigger: ring.current,
              toggleActions: 'play none none pause',
              start: '50% bottom'
            }
        })

        tl.set($('.main-circle'), { opacity:0, scale: 0.5, rotation:'200deg'})
        tl.set($('.title, .copy'), { opacity:0, scale:1.05 })
        tl.to($('.main-circle'), { opacity:1, scale: 1, rotation:'0deg'})
        tl.to($('.title'), { duration: 0.5, opacity:1, scale:1, ease: "power2.out" },"-=0.1")
        tl.to($('.copy'), { duration: 0.5, opacity:1, scale:1, ease: "power2.out"},"-=0.2")


    }, []); 

    return(
        <Wrapper ref={ring}>
            <img ref={ring} className="main-circle" srcSet={`${circle} 2x`} alt="Trusted, Authentic, Humble, Versatile, Inclusive"/>
            <div className="copy-container">
                <div className="title">{data.title}</div>
                <div className="copy">{data.copy}</div>
            </div>
        </Wrapper>
    )
}

export default InfoCircle