//Components
import React from "react";
import TypeWriteLine from "../../TypeWriteLine/TypeWriteLine"
import Counter from "../../Counter/Counter";
import BottomLine from "../../BottomLine/BottomLine";
//Styles
import { Block, Inner } from "./PeopleFirstBlock.styled";
//Assets
import chooseLogo from "../../../images/choose-logo.svg"


const PeopleFirstBlock = ({data})=>{

    console.log(data)

    return(
        <Block>
            <Inner>
                <img className="chooseLogo" src={chooseLogo} alt="Choose"/>
                <TypeWriteLine copy={data.animatedTitle}/>

                <h2>{data.subtitle}</h2>
                <p>{data.mainCopy}</p>
            
                <div className="counters-grid">
                    {data.counters.map((item, index)=>(
                        <Counter key={index} countNumber={item.number} text={item.itemText} symbol={item.symbol} symbolPositioning={item.symbolPositioning}/>
                    ))}
                </div>
            </Inner>

            <BottomLine/>
        </Block>
    )
}

export default PeopleFirstBlock