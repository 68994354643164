import tw, { styled } from "twin.macro"

export const SlidesWrapper = styled.div`

    .slides-tray{
        ${tw`flex w-full max-w-screen-xl mx-auto  px-0 sm:py-6  sm:p-6  box-border`}

        .mask{
            ${tw`w-full justify-between box-border    flex flex-nowrap   overflow-x-hidden`}
        }

        button{
            ${tw`bg-transparent border-0`}

            &.arrow-back{
                ${tw`mr-0 sm:mr-10`}
            }

            &.arrow-forth{
                ${tw`ml-0 sm:ml-10`}
            }
        }
    }

    .slide{
        ${tw`flex flex-col items-center w-full sm:w-1/2 lg:w-1/3 `}
        flex: 0 0 auto;

        .badge{
            max-width: 210px;
            width: 100%;
        }

        h3{
            ${tw`text-darkgray text-center font-thin m-0 mt-4 px-4 whitespace-normal`}
        }
    }
`