import tw, { styled } from "twin.macro"
import { GatsbyImage } from "gatsby-plugin-image"

export const Wrapper = styled.div`
  ${tw`relative overflow-hidden`}

  @media (min-width: 641px){
    max-height: 640px;
  }
`

export const DesktopMainImage = styled(GatsbyImage)`
  ${tw`hidden sm:block`}
`

export const MobileMainImage = styled(GatsbyImage)`
  ${tw`block sm:hidden`}
`

export const TextBlock = styled.div`
  ${tw`absolute inset-0 max-w-screen-xl py-2 md:py-2 lg:py-8 px-8 xl:px-0 mx-auto`}

  h2, p{     
    ${tw`ml-auto text-center font-semibold`}
    width: 55%;
    @media (max-width: 768px){
      width: 60%;
    }
  }

  h2{
    ${tw`text-orange uppercase   mt-12 sm:my-4 md:mt-12   text-5xl sm:text-3xl lg:text-5xl`}

    @media (max-width: 640px){
      height: min-content;
    }
  }

  p{
    ${tw`hidden sm:block text-darkgray`}
  }
`

export const TextBlockMobile = styled.div`
  ${tw`block sm:hidden bg-lightorange text-white static max-w-screen-xl py-4 md:py-2 lg:py-8 px-8 xl:px-0 mx-auto`}

  p{ 
    ${tw`ml-auto text-center font-semibold`}
  }
`

