import tw, { styled } from "twin.macro"
import { GatsbyImage } from "gatsby-plugin-image"

export const Wrapper = styled.div`
  ${tw`relative overflow-hidden`}
`

export const DesktopMainImage = styled(GatsbyImage)`
  ${tw`hidden sm:block`}
`

export const MobileMainImage = styled(GatsbyImage)`
  ${tw`block sm:hidden`}
`

export const TextBlock = styled.div`

  @media (max-width: 640px){
    background-image: ${({ mobileImage }) => `url(${mobileImage})`};
  }

  background-image: ${({ desktopImage }) => `url(${desktopImage})`};
  background-position: top right;

  ${tw`bg-cover pt-44 pb-12 mx-auto box-border`}

  .text-wrap{
    ${tw`max-w-screen-xl mx-auto`}

    .box{
      ${tw`pl-8 xl:pl-0`}
      width: 55%;
    }
  }

  .white-ribbon{
    ${tw`overflow-hidden hidden sm:block bg-glass`}

    p{
      ${tw`block sm:hidden m-0 text-darkgray px-8 xl:px-0 mx-auto text-left text-lg`}
    }
  }

  h2, p{  ${tw`text-center font-thin`} }

  h2{
    ${tw`text-left w-3/4 sm:w-auto sm:text-center text-white my-0 sm:mx-auto mb-0`}
  }

  & p{
    ${tw`hidden sm:block text-white font-thin mt-20 mb-20 mx-auto`}
    max-width: 40rem;

    a{
      ${tw`w-max bg-transparent text-center border-0 border-8 border-solid border-lightorange px-8 py-1 rounded-full font-bold no-underline text-orange mx-auto mt-4 md:mt-10`}
    }

  }
`

export const TextBlockMobile = styled.div`
  ${tw`block sm:hidden bg-lightgray px-8 xl:px-0 mx-auto py-10`}

  p{
    ${tw`m-0 mb-6 text-xl font-thin`
  }
`

