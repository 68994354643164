import tw, { styled } from "twin.macro"
import { GatsbyImage } from "gatsby-plugin-image"

export const Wrapper = styled.div`
  ${tw`relative overflow-hidden`}

  min-height: 520px;

  @media (min-width: 641px){
    max-height: 640px;
  }
`

export const DesktopMainImage = styled(GatsbyImage)`
  ${tw`hidden sm:block static`}
`

export const MobileMainImage = styled(GatsbyImage)`
  ${tw`block sm:hidden mb-8`}
`

export const TextBlock = styled.div`

  ${tw`hidden sm:block absolute inset-0 max-w-screen-xl py-2 md:py-2 lg:py-4 px-8 xl:px-0 mx-auto`}

  h2, p{     
    ${tw`text-center font-semibold`}
    width: 55%;
    @media (max-width: 768px){
      width: 60%;
    }
  }

  h2{
    ${tw`text-lightorange uppercase   mt-12 sm:my-4 md:mt-8 lg:mt-8 xl:mt-12  text-5xl sm:text-2xl md:text-2xl lg:text-4xl`}
    width: 42%;
    margin-left: 4%;


    @media (max-width: 640px){
      height: min-content;
    }
  }

  p{
    ${tw`text-darkgray font-thin lg:text-xl xl:text-2xl`}
    width: 42%;
    margin-left: 4%;
    @media (max-width: 1024px){
      width: 48%;
      margin-left: 0%;
    }

    a{
      ${tw`
        bg-transparent border-lightorange text-orange  hover:bg-lightorange hover:text-white  transition-all
        w-max text-center border-0 border-8 border-solid  px-8 py-1 rounded-full font-bold no-underline  mx-auto mt-4 md:mt-10
      `}
    }

  }
`

export const TextBlockMobile = styled.div`
  ${tw`block sm:hidden bg-white text-orange static max-w-screen-xl pb-4 md:pb-2 lg:pb-8 px-8 xl:px-0 mx-auto`}

  h2{
    ${tw`mt-0 font-bold text-4xl`}
  }

  p{ 
    ${tw`ml-auto text-left text-darkgray text-xl font-thin`}
  }

  a{
    ${tw`
      bg-lightorange text-white
      text-center px-8 py-1 rounded-full font-bold no-underline  mt-2 mb-12 inline-block
    `}
  }
`

