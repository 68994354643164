import React from "react"
import { Link } from "gatsby"
import {Wrapper, TextBlock} from "./InfoBlockWithCircles.styled"
import InfoCircle from "../InfoCircle/InfoCircle"
import BottomLine from "../../BottomLine/BottomLine"

const infoBlock = ({info, bgColor})=> { 

    console.log(info)
    return(
        <Wrapper bgColorClass={bgColor}>
            <TextBlock dangerouslySetInnerHTML={{ __html: info.copy }}/>
            <div className="circles-container">
                <InfoCircle data={info.circle1} />
                <InfoCircle data={info.circle2} />
                <InfoCircle data={info.circle3} />
            </div>
            <div className="cta-container"><Link className={`cta white`} to={info.ctaUrl}>{info.ctaLabel}</Link></div>
            <div className="cta-container-2"><Link className={`cta white`} to={info.ctaUrl}>{info.ctaLabelMobile}</Link></div>
            <BottomLine/>
        </Wrapper>
    )
}

export default infoBlock;