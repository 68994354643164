import React from "react"
import { Link } from "gatsby"
import { Wrapper, DesktopMainImage, MobileMainImage, TextBlock, TextBlockMobile } from "./RealTimeBlock.styled"
import 'twin.macro'

//Assets
import BottomLine from "../../BottomLine/BottomLine"

// Query hook



const RealTimeBlock = ({data})=> {

    console.log(data.cta.text)

    return(
        <Wrapper>
            <DesktopMainImage image={data.desktopFeaturedImage.localFile.childImageSharp.gatsbyImageData} alt=""/>
            <MobileMainImage image={data.mobileFeaturedImage.localFile.childImageSharp.gatsbyImageData} alt=""/>

            <TextBlock>
                <h2>{data.title}</h2>
                <p>
                    {data.mainCopy}
                    <Link tw="block" className={`cta ${data.cta.cssclass}`} to={data.cta.url}>{data.cta.text}</Link>
                </p>
            </TextBlock>

            <TextBlockMobile>
                <h2>{data.title}</h2>
                <p>{data.mainCopy}</p>
                <Link className={`cta ${data.cta.cssclass}`} to={data.cta.url}>{data.cta.text}</Link>
            </TextBlockMobile>


            <BottomLine/>
        </Wrapper>
    )
}

export default RealTimeBlock