import React from "react"
import { Wrapper, TextBlock, TextBlockMobile } from "./Awards.styled"
import 'twin.macro'
import Carousel from "../Carousel/Carousel"

//Assets
import BottomLine from "../../BottomLine/BottomLine"

// Query hook



const Awards = ({data})=> {

    const content = data.wpPage.ACF_AboutPage.awardsBlock
    const posts = data.allWpPost.edges.map( (item) => {
        return {
            image: item.node.featuredImage.node.localFile.publicURL,
            title: item.node.title
        }
    })

    console.log(content.desktopFeaturedImage.localFile.publicURL)

    return(
        <Wrapper>
            <TextBlock desktopImage={content.desktopFeaturedImage.localFile.publicURL} mobileImage={content.mobileFeaturedImage.localFile.publicURL}>
                
                <div className="text-wrap">
                    <div className="box">
                        <h2>{content.content.title}</h2>
                        <p>{content.content.message}</p>
                    </div>
                </div>

                <div className="white-ribbon">
                    <p>{content.content.message}</p>
                    <Carousel slidesArray={posts} />
                </div>

            </TextBlock>

            <TextBlockMobile>
                    <p>{content.content.message}</p>
                    <Carousel slidesArray={posts} />
            </TextBlockMobile>

            <BottomLine/>
        </Wrapper>
    )
}

export default Awards