import tw, { styled } from "twin.macro"

export const Wrapper = styled.div`
    ${tw`w-full relative`}
    background-color: ${({ bgColorClass }) => `${bgColorClass}`};
    .cta-container {
        ${tw`w-full flex justify-center pb-10 mt-10 md:pb-16 text-center hidden md:block`}
    }
    .cta.white{
        background: #fff;
        text-transform: uppercase;
        color: #C53427;
        font-size: 18px;
        border-radius: 9999px;
        border: 7px solid #F36F34;
        text-decoration: none;
        transition-property: all;
        transition-duration: 150ms;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        ${tw`md:px-8 px-4 font-bold text-lg`}
    }
    .cta.white:hover{
        color: #fff;
        background: #F36F34;
        border: 7px solid #fff;
    }
    .circles-container{
        ${tw`max-w-screen-xl grid grid-cols-1 md:grid-cols-3 gap-10 md:gap-0 mx-auto`}
    }
    .cta-container-2 {
        ${tw`w-full flex justify-center pb-10 mt-10 md:pb-16 text-center block md:hidden`}
    }

    .cta-container-2 .cta.white {
        color: #fff;
        background: #F36F34;
        border: 7px solid #fff;
    }

    .cta-container-2 .cta.white:hover {
        color: #C53427;
        background: #fff;
        border: 7px solid #F36F34;
    }
`

export const TextBlock = styled.div`
    ${tw`max-w-screen-xl py-2 md:py-2 lg:py-8 px-8 xl:px-0 mx-auto`}

    h2{
        ${tw`text-left md:text-center text-orange my-0 mt-8 md:mt-0`}
    }

    p.main-message{
        ${tw`text-left md:text-center mx-auto`}
        max-width: 820px;
    }

    p.before-circles{
        ${tw`text-left md:text-center mx-auto text-orange font-bold md:mt-10`}
        max-width: 820px;
    }

    .highlight {
        ${tw`font-bold`}
        color: #C53427;
    }

    .only-mobile {
        ${tw`block md:hidden`}
    }

    .tile-grid{
        ${tw`grid  mx-auto mt-12`}
        grid-template-columns: 1fr auto;
        max-width: 880px;

        .tile{

            ${tw`mb-8`}

            h3{
                ${tw`text-orange font-normal text-2xl m-0 mb-2`}
            }

            ul{
                ${tw`list-none p-0 pl-3`}

                li{
                    &:before{
                        ${tw`absolute text-orange inline -ml-3`}
                        content: "•"
                    }
                }
            }
        }

        @media (max-width: 840px){
            max-width: 480px;

            ${tw`grid-cols-1`}

            h3{ ${tw`text-center`} }
            ul{
                width: 100%;
                max-width: 20rem;
                margin: 0 auto;
                display: block;
            }
        }
    }
`