import React, { useEffect, useRef } from "react";

//Styles
import { Ring } from "./Counter.styled";

//Assets
import RingImage from "../../images/About/counter-ring.png"

import gsap from 'gsap';

const Counter = ({countNumber, text, symbol, symbolPositioning})=>{

    const ring = useRef();
    const valueToAnimate = useRef();

    useEffect(() => {

        const $ = gsap.utils.selector(ring);
        let data = {num: 0}
        let tl = new gsap.timeline({
            scrollTrigger: {
              trigger: ring.current,
              toggleActions: 'play none none pause',
              start: '50% bottom'
            }
        })

        .set(valueToAnimate.current, {width: valueToAnimate.current.offsetWidth+'px', display:'inline-block'})
        .set(valueToAnimate.current, {innerHTML: ""})
        .set(ring.current, {opacity:1})
        .set($('.ring, .number, .label, .symbol'), { opacity: 0 })
        .set($('.ring'), { scale: 0.5, rotation:'200deg'})
        .set($('.number'), { scale: 0.75})
        .set($('.label'), { scale: 1.2})
        .to($('.ring'), {duration:0.8, opacity:1, rotation:'0deg', scale:1, ease: "power3.out"})
        .to($('.number'), {duration: 0.3, opacity:1, scale:1, ease: "power3.out"}, "-=0.5")
        .to(data, {duration: 1, num: countNumber, ease: "power3.out", onUpdate:()=>{
            valueToAnimate.current.innerHTML = Math.round(data.num);
        }}, "-=0.3")   
        .to($('.symbol'), {duration: 0.3, opacity:1, ease: "power3.out"}, "-=0.8")
        .to($('.label'), {duration: 0.3, opacity:1, scale:1, ease: "power3.out"}, "-=0.2")

        // cleanup function will be called when component is removed
        return () => {
            tl.kill();
        };

    }, [countNumber]); 
    return(
        <Ring ref={ring}>
            <img  className="ring" src={RingImage} alt="" width="149" height="171"/>
            <div className="info-wrap">
                <p className="number">
                    <span ref={valueToAnimate}>{countNumber}</span>
                    {
                        symbolPositioning === "sup" 
                            ? (<sup className="symbol">{symbol}</sup>) 
                            : (<sub className="symbol">{symbol}</sub>)
                    }
                </p>
                <p className="label">{text}</p>
            </div>
        </Ring>
    )
}

export default Counter