import React from "react"
import { Wrapper, DesktopMainImage, MobileMainImage, TextBlock, TextBlockMobile } from "./ImageTextBlock.styled"
import 'twin.macro'

//Assets
import BottomLine from "../BottomLine/BottomLine"

// Query hook



const ImageTextBlock = ({title, mainCopy, data})=> {
    return(
        <Wrapper>
            <DesktopMainImage image={data.desktopFeaturedImage.localFile.childImageSharp.gatsbyImageData} alt=""/>
            <MobileMainImage image={data.mobileFeaturedImage.localFile.childImageSharp.gatsbyImageData} alt=""/>

            <TextBlock>
                <h2>{data.title}</h2>
                <p>{data.mainCopy}</p>
            </TextBlock>

            <TextBlockMobile>
                <p>{data.mainCopy}</p>
            </TextBlockMobile>

            <BottomLine/>
        </Wrapper>
    )
}

export default ImageTextBlock