import tw, { styled } from "twin.macro"

export const Wrapper = styled.div`
    ${tw`block relative overflow-hidden`}
    min-width: 164px;
    text-align: center;


    .main-circle{
     ${tw`relative z-10 opacity-0`}
    }

    .img-wrapper{
        ${tw`overflow-hidden`}
    }

    .copy-container {
        ${tw`absolute w-full` };
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        max-width:300px;
    }

    .title {
        ${tw`text-orange font-bold text-lg md:text-xl text-center uppercase opacity-0`}
    }
    .copy {
        ${tw`text-darkgray text-base md:text-lg text-center opacity-0`}
    }

    @media (max-width: 640px) {
        .main-circle {
            max-width: 178px;
        }
    }
`