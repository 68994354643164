import tw, { styled } from "twin.macro"

export const Block = styled.div`

    ${tw`relative bg-lightgray`}

    .chooseLogo{
        ${tw`block w-min`}    
    }
`

export const Inner = styled.div`

    ${tw`w-full max-w-screen-xl mx-auto py-16 px-8 xl:px-0 box-border`}

    .chooseLogo{
        ${tw`block w-min`}    
    }
    .counters-grid{
        ${tw`grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 place-items-center mx-auto`}
        max-width: 980px;
        @media (max-width: 1024px){
            max-width: 640px;
        }
    }


    & > p{
        ${tw`text-center mx-auto`}
        max-width: 730px;
    }
    h2{
        ${tw`text-center`}
    }
`

