import * as React from "react"

import Layout from "../components/Layout/Layout"
import Seo from "../components/Seo/Seo"
import MainImage from "../components/About/MainImage/MainImage"
import PeopleFirstBlock from "../components/About/PeopleFIrstBlock/PeopleFirstBlock"
import ImageTextBlock from "../components/ImageTextBlock/ImageTextBlock"
import RealTimeBlock from "../components/About/RealTimeBlock/RealTimeBlock"
import Awards from "../components/About/Awards/Awards"
import InfoBlockWithCircles from "../components/About/InfoBlockWithCircles/InfoBlockWithCircles"


import { useAboutQuery } from "../hooks/useAboutQueries"


const About = ({ children }) => {

  const data = useAboutQuery();

  return(
    <Layout>
      <Seo title="About" />

      <MainImage assets={data.wpPage.ACF_AboutPage.topMainImage} />

      <PeopleFirstBlock data={data.wpPage.ACF_AboutPage.countersBlock}/>

      <ImageTextBlock data={data.wpPage.ACF_AboutPage.textImageBlock1}/>

      <InfoBlockWithCircles info={data.wpPage.ACF_AboutPage.infoBlockWithCircleData}  bgColor='#EBEAEA'/>

      <RealTimeBlock data={data.wpPage.ACF_AboutPage.textImageBlock2}/>

      <Awards data={data}/>

    </Layout>
  )

}

export default About
