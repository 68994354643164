import React, { useRef, useEffect } from "react";
import { SlidesWrapper } from "./Carousel.styles";
import gsap from 'gsap';

import ArrowBack from "../../../images/arrow-back.svg"
import ArrowForth from "../../../images/arrow-forth.svg"

const Carousel = ({slidesArray})=> {

    let slideIndex = 0;
    const mask = useRef();
    const back = useRef();
    const forth = useRef();
    const $ = gsap.utils.selector(mask);
    let timeout;

    useEffect(() => {
        checkArrowsState();
        window.addEventListener('resize', checkArrowsStateDelay);

        return () => {
            window.removeEventListener('resize', checkArrowsStateDelay);
        }
    }); 

    const checkArrowsStateDelay = ()=> {
        clearTimeout(timeout);
        timeout = setTimeout(()=>{
            checkArrowsState()
        }, 100)
    }


    const goBack = (e)=>{
        if(slideIndex > 0){
            slideIndex--;
            requestSlide()
        }
    }

    const goForth = (e)=>{
        if( slideIndex < (slidesArray.length) - Math.round(mask.current.offsetWidth / $('.slide')[0].offsetWidth)){
            slideIndex++;
            requestSlide()
        }
    }

    const requestSlide = ()=> {
        gsap.to($('.slide'), {duration:0.3, ease:"power2.out", x:`-${100 * slideIndex}%`})
        checkArrowsState();
    }

    const checkArrowsState = ()=> {
        (slideIndex === 0)
            ? gsap.set(back.current, {opacity:0.25, 'pointer-events': 'none'})
            : gsap.set(back.current, {opacity:1, 'pointer-events': 'all'});
        
        (slideIndex === (slidesArray.length - Math.round(mask.current.offsetWidth / $('.slide')[0].offsetWidth)))
            ? gsap.set(forth.current, {opacity:0.25, 'pointer-events': 'none'})
            : gsap.set(forth.current, {opacity:1, 'pointer-events': 'all'});
    }

    return(
        <SlidesWrapper>
            <div className="slides-tray">

                <button onClick={goBack} ref={back} className="arrow-back">
                    <img src={ArrowBack} alt=""/>
                </button>

                <div className="mask" ref={mask}>
                {
                    slidesArray.map( (item, index)=> (
                        <div className="slide" key={index}>
                            <img className="badge" src={item.image} alt=""/>
                            <h3>{item.title}</h3>
                        </div>
                    ))
                }
                </div>

                <button onClick={goForth} ref={forth} className="arrow-forth">
                    <img src={ArrowForth} alt=""/>
                </button>
            </div>
        </SlidesWrapper>
    )

}

export default Carousel