import { useStaticQuery, graphql } from "gatsby"

export const useAboutQuery = ()=> {

    return useStaticQuery(graphql`
    
        query About {
            wpPage(title: {eq: "ABOUT"}) {

                ACF_AboutPage {


                    topMainImage {
                        desktopImage {
                            localFile {
                                childImageSharp {
                                    gatsbyImageData
                                }
                            }
                        }
                        mobileImage {
                            localFile {
                                childImageSharp {
                                    gatsbyImageData
                                }
                            }
                        }
                    }

                    countersBlock {
                        animatedTitle
                        mainCopy
                        subtitle
                        counters {
                            itemText
                            number
                            symbol
                            symbolPositioning
                        }
                    }
                    
                    textImageBlock1 {
                        title
                        mainCopy
                        desktopFeaturedImage {
                            localFile {
                                childImageSharp {
                                    gatsbyImageData
                                }
                            }
                        }
                        mobileFeaturedImage {
                            localFile {
                                childImageSharp {
                                    gatsbyImageData
                                }
                            }
                        }
                    }


                    textImageBlock2 {
                        title
                        mainCopy
                        cta {
                            cssclass
                            fieldGroupName
                            text
                            url
                        }
                        desktopFeaturedImage {
                            localFile {
                                childImageSharp {
                                    gatsbyImageData
                                }
                            }
                        }
                        mobileFeaturedImage {
                            localFile {
                                childImageSharp {
                                    gatsbyImageData
                                }
                            }
                        }
                    }


                    infoBlock2


                    awardsBlock {
                        desktopFeaturedImage {
                            localFile {
                                childImageSharp {
                                    gatsbyImageData
                                }
                                publicURL
                            }
                        }
                        mobileFeaturedImage {
                            localFile {
                                childImageSharp {
                                    gatsbyImageData
                                }
                                publicURL
                            }
                        }
                        content {
                            message
                            title
                        }
                    }

                    infoBlockWithCircleData {
                        copy
                        circle1 {
                            title
                            copy
                        }
                        circle2 {
                            title
                            copy
                        }
                        circle3 {
                            title
                            copy
                        }
                        ctaLabel
                        ctaLabelMobile
                        ctaUrl
                    }

                }
            }

            allWpPost(filter: {categories: {nodes: {elemMatch: {name: {eq: "Awards"}}}}}) {
                edges {
                    node {
                        title
                        featuredImage {
                            node {
                                localFile {
                                    publicURL
                                }
                            }
                        }
                    }
                }
            }

        }

    `)
}