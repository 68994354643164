import tw, { styled } from "twin.macro"

export const Ring = styled.div`
    ${tw`relative w-full opacity-0`}

    .info-wrap{
        ${tw`absolute inset-0 flex flex-col justify-center`}
    }    

    .ring{
        ${tw`mx-auto static block`}
    }

    .number{
        ${tw`text-center font-bold text-6xl m-0 p-0 text-darkgray -mt-2`}
    }

    .symbol{
        ${tw`text-lightorange`}
        line-height:0;
    }

    sup{
        vertical-align: 1rem;
    }

    sub{
        vertical-align: baseline;
    }


    .label{
        ${tw`text-lg whitespace-nowrap text-center m-0 p-0 uppercase -mt-2`
    }

`